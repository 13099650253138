import request from '../utils/request';

//获取新闻列表
export const getNewsList = (current, size, params) => {
  return request({
    url: "/news/blade-cms/news/front/list",
    method: "get",
    showLoading:true,
    params: {
      ...params,
      current, 
      size
    },
  });
};
// 客户端首页轮播图接口
export const getNews = (current, size, params) => {
  return request({
    url: "/api/blade-news/cmsnews/front/newspage",
    method: "get",
    showLoading:true,
    params: {
      ...params,
      current, 
      size
    },
  });
};
// 客户端首页新闻分页
export const getIndexNews = (current, size, params) => {
  return request({
    url: "/api/blade-news/cmsnews/front/page",
    method: "get",
    showLoading:true,
    params: {
      ...params,
      current, 
      size
    },
  });
};

// 客户端权威发布
export const getReleaseNews = (current, size, params) => {
  return request({
    url: "/api/blade-news/cmsnews/front/qwpage",
    method: "get",
    showLoading:true,
    params: {
      ...params,
      current, 
      size
    },
  });
};

// 客户端媒体监督
export const getMediaNews = (current, size, params) => {
  return request({
    url: "/api/blade-news/cmsnews/front/mtpage",
    method: "get",
    showLoading:true,
    params: {
      ...params,
      current, 
      size
    },
  });
};

export const getNewsDetail = (id) => {
  return request({
    url: "/api/blade-news/cmsnews/front/"+id,
    // url: "/api/blade-news/cmsnews/detail",
    method: "get",
    showLoading:true,
    params: {
      id:id
    },
  });
};

export const getNewsOut = (id,size) => {
  return request({
    url: `https://app.qz123.com/api/nav_share/channel?tenantId=55&is_new=true&id=${id}&size=${size}`,
    method: "get",
    showLoading:true
  });
};