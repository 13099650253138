<template>
  <div class="index">
    <div class="box1">
      <el-carousel arrow="never" height="192px">
        <el-carousel-item
          :style="{ backgroundImage: 'url(' + item.thumb + ')' }"
          v-for="item in hotThumbNews"
          :key="item.id"
          @click.native="hotInlineNewsClick(item)"
        >
          <p class="text">{{ item.title }}</p>
        </el-carousel-item>
      </el-carousel>

      <div class="rt" v-if="NewsList.length">
        <div class="hot" @click="hotNewsClick(NewsList[0])">
          <el-image
            class="icon"
            :src="require('images/index/icon1.png')"
          ></el-image>
          <p class="txt">{{ NewsList[0].doc_title }}</p>
        </div>
        <ol>
          <li
            v-for="(item, index) in NewsList"
            :key="item.id"
            v-if="index > 0"
            @click="hotNewsClick(item)"
          >
            <span class="txt">{{ item.doc_title }}</span>
            <span class="date">{{ item.created_at | formatTime }}</span>
          </li>
        </ol>
      </div>
    </div>
    <div class="box2">
      <el-image
        class="title__img"
        :src="require('images/index/zxwz.png')"
      ></el-image>
      <el-image
        class="title__img__tips"
        :src="require('images/index/zxwz-tips.png')"
      ></el-image>
      <div class="header">
        <ul class="tabs">
          <li
            v-for="(item, index) in orderTypes"
            :key="index"
            :class="{ current: item.value == orderType }"
            @click="onOrderTypeClick(item)"
          >
            {{ item.key }}
          </li>
        </ul>
        <div class="more" @click="askMore">更多+</div>
      </div>
      <div
        class="content"
        v-if="askList.length"
        @click="onAskClick(askList[0])"
      >
        <div class="title">
          <div class="tag">问</div>
          <span class="txt">{{ askList[0].title }}</span>
        </div>
        <div class="desc" v-html="htmlToText(askList[0].content)"></div>
        <div class="next">
          <div class="lt">发布于 {{ askList[0].handelTime }}</div>
          <div class="rt">
            <span
              v-if="askList[0].statusName"
              v-html="askList[0].statusName"
            ></span>
            <span v-if="askList[0].deptName">{{ askList[0].deptName }}</span>
          </div>
        </div>
      </div>
      <el-empty v-else description="暂无数据"></el-empty>
      <ul class="list">
        <li
          class="item"
          v-for="item in askList.slice(1)"
          :key="item.id"
          @click="onAskClick(item)"
        >
          <div class="tag">问</div>
          <span class="txt">{{ item.deptName }} | {{ item.title }}</span>
        </li>
      </ul>
    </div>
    <div class="box3" v-if="false">
      <el-image
        class="title__img"
        :src="require('images/index/mjzk.png')"
      ></el-image>
      <ul class="list">
        <li
          class="item"
          v-for="item in commentsList.slice(0, 2)"
          :key="item.id"
          @click="zkDetail(item)"
        >
          <div class="title">{{ item.title }}</div>
          <div class="desc">{{ item.content }}</div>
          <div class="next">
            <div class="lt">发布于 {{ item.createTime }}</div>
            <div class="rt">{{ item.targetName }}</div>
          </div>
          <el-image
            class="pos__icon"
            :src="require('images/index/jdz.png')"
          ></el-image>
        </li>
      </ul>
    </div>
    <div class="box4">
      <el-image
        class="title__img"
        :src="require('images/index/bmzx.png')"
      ></el-image>
      <div class="header">
        <ul class="tabs">
          <li
            v-for="(item, index) in deptCategorys"
            :key="index"
            :class="{ current: item.value == deptCategory }"
            @click="onDeptClick(item)"
          >
            {{ item.key }}
          </li>
        </ul>
        <div class="more" @click="deptMore">更多+</div>
      </div>
      <el-row :gutter="18">
        <el-col
          :span="6"
          v-for="item in deptList"
          :key="item.id"
          @click.native="onDeptList(item)"
        >
          <div>
            {{ item.deptName }}
            <span
              class="outChainUrl"
              v-if="item.outChainUrl && item.outChainUrl !== ''"
              >（外）</span
            >
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {
  getNewsList,
  getNews,
  getIndexNews,
  getMediaNews,
  getNewsOut,
} from "@/api/news";
import { getAskList } from "@/api/wzasksevent";
import { getZKPage } from "@/api/essay";
import { getDeptList } from "@/api/dept";
import { htmlToText, formateDate } from "@/utils/tools";
export default {
  name: "index",
  data() {
    return {
      hotThumbNews: [],
      hotTextNews: [],
      NewsList: [],
      askList: [],
      commentsList: [],
      orderType: 1,
      htmlToText: htmlToText,
      orderTypes: [
        {
          key: "最新热点",
          // key: "本月热点",
          value: 1,
        },
        {
          key: "最新问政",
          value: 2,
        },
        {
          key: "最新答复",
          value: 3,
        },
      ],
      deptCategory: 2,
      deptCategorys: [
        {
          key: "部门在线",
          value: 2,
        },
        {
          key: "区县在线",
          value: 1,
        },
        // {
        //   key: "社区在线",
        //   value: 5,
        // },
      ],
      deptList: [],
    };
  },
  filters: {
    formatTime(val) {
      return formateDate(val, "YYYY-MM-DD");
    },
  },
  mounted() {
    // 获取热点图片新闻
    this.getHotNewsThumbList();
    // 获取置顶热点文字新闻
    // this.getHotNewsTextList();
    // 获取热点文字新闻
    this.getMediaNewsTextList();
    // 获取在线问政
    this.getAskList();
    // 获取民间智库列表
    this.getCommentsList();
    // 获取部门
    this.getDeptList();
  },
  methods: {
    zkDetail(item) {
      this.$router.push("/web/zkdetail/" + item.id);
    },
    onDeptList(item) {
      if (item.outChainUrl && item.outChainUrl !== "") {
        window.open(item.outChainUrl);
      } else {
        this.$router.push({
          path: `/web/deptlist/${item.id}/${item.deptName}/1/null`,
        });
      }
      // this.$router.push({
      //   path: `/web/deptlist/${item.id}/${item.deptName}/1/null`,
      // });
    },
    async getDeptList() {
      const res = await getDeptList(this.deptCategory);
      // console.log(res)
      this.deptList = res.data.slice(0, 12);
    },
    async getCommentsList() {
      const res = await getZKPage(1, 5, {
        isGoldenIdea: true,
      });
      // console.log(res)
      this.commentsList = res.data.records;
    },
    onAskClick(item) {
      // console.log(item);
      this.$router.push({
        path: "/web/detail/",
        query: {
          id: item.id,
        },
      });
    },
    async getAskList() {
      const res = await getAskList(1, 7, {
        orderType: this.orderType,
      });
      // console.log(res);
      this.askList = res.data.records;
    },
    hotInlineNewsClick(item) {
      if (item.externalUrl !== "" && item.externalUrl) {
        window.open(item.externalUrl);
      } else {
        this.$router.push(`/web/newDetail/${item.id}`);
      }
    },
    hotNewsClick(item) {
      if (item.share_url !== "" && item.share_url) {
        window.open(item.share_url);
      } else {
        this.$router.push(`/web/newDetail/${item.id}`);
      }
      // window.open(item.linkNewsUrl);
    },
    async getHotNewsTextList() {
      const res = await getNews(1, 1, {
        isTop: 1,
        descs: "sort,publish_time",
        unCategoryIds: "1643138279161692162",
      });
      // console.log(res);
      this.hotTextNews = res.data.records;
    },

    async getMediaNewsTextList() {
      const res = await getNewsOut("6303163dad61a4361610dbe0", 5);
      // console.log(res);
      let records = res.data.article_list;
      this.NewsList = records;
    },

    async getHotNewsThumbList() {
      const res = await getNews(1, 3, {
        categoryIds: "1643138279161692162",
        descs: "is_top,publish_time,sort",
      });
      this.hotThumbNews = res.data.records;
    },
    deptMore() {
      this.$router.push("/web/depts");
    },
    askMore() {
      this.$router.push("/web/online/1/1");
    },
    onOrderTypeClick(item) {
      this.orderType = item.value;
      this.getAskList();
    },
    onDeptClick(item) {
      this.deptCategory = item.value;
      this.getDeptList();
    },
  },
};
</script>

<style lang="scss" scoped>
.index {
  .box1 {
    background: #ffffff;
    padding: 20px;
    display: flex;
    .el-carousel {
      width: 280px;
      position: relative;
      .el-carousel__item {
        background-color: #eeeeee;
        background-size: cover;
        background-position: left center;
        background-repeat: no-repeat;
        .text {
          position: absolute;
          bottom: 20px;
          left: 10px;
          font-size: 14px;
          color: #ffffff;
          line-height: 14px;
          width: 260px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .rt {
      flex: 1;
      padding-left: 24px;

      .hot {
        display: flex;
        align-items: center;
        cursor: pointer;
        .icon {
          width: 40px;
          height: 20px;
        }
        .txt {
          font-size: 18px;
          font-weight: 700;
          color: #252b33;
          padding-left: 6px;
          max-width: 400px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      ol {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%;
        li {
          font-size: 16px;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          .txt {
            color: #3f4751;
            padding-left: 6px;
            max-width: 300px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .date {
            color: #999999;
          }
        }
      }
    }
  }
  .box2 {
    background: #ffffff;
    padding: 20px;
    display: flex;
    margin-top: 16px;
    flex-direction: column;
    position: relative;
    .title__img {
      width: 770px;
    }
    .title__img__tips {
      position: absolute;
      width: 58px;
      left: 494px;
      top: 9px;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #eeeeee;
      .tabs {
        display: flex;
        li {
          font-size: 18px;
          color: #999999;
          margin-right: 26px;
          padding: 14px 0;
          cursor: pointer;
          &.current {
            font-size: 22px;
            font-weight: 600;
            color: #333333;
            border-bottom: 4px solid #5cbd31;
          }
        }
      }
      .more {
        font-size: 14px;
        color: #999999;
        cursor: pointer;
      }
    }
    .content {
      background: #f9fafc;
      padding: 20px;
      margin-top: 20px;
      cursor: pointer;
      .title {
        display: flex;
        align-items: center;
        .tag {
          font-size: 13px;
          color: #5cbd31;
          padding: 5px;
          border-radius: 5px;
          border: 1px solid #5cbd31;
        }
        .txt {
          font-size: 18px;
          color: #333333;
          margin-left: 8px;
        }
      }
      .desc {
        font-size: 14px;
        color: #666666;
        line-height: 1.8;
        // line-height: 26px;
        margin-top: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .next {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 26px;
        .lt {
          font-size: 13px;
          color: #666666;
        }
        .rt {
          display: flex;
          span {
            font-size: 13px;
            color: #666666;
            padding: 5px;
            background: #e8eaed;
            margin-left: 10px;
          }
        }
      }
    }
    .list {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      flex-wrap: wrap;
      .item {
        display: flex;
        align-items: center;
        margin-bottom: 18px;
        cursor: pointer;
        .tag {
          font-size: 13px;
          color: #5cbd31;
          padding: 5px;
          border-radius: 5px;
          border: 1px solid #5cbd31;
        }
        .txt {
          font-size: 16px;
          color: #333333;
          margin-left: 8px;
          white-space: nowrap;
          width: 334px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .box3 {
    background: #ffffff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    .title__img {
      width: 770px;
    }
    .list {
      display: flex;
      flex-direction: column;
      margin-top: 28px;
      .item {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        padding-bottom: 14px;
        position: relative;
        margin-bottom: 14px;
        &:not(:last-child) {
          border-bottom: 1px solid #eeeeee;
        }
        .title {
          font-size: 18px;
          font-weight: 700;
          color: #333333;
        }
        .desc {
          font-size: 14px;
          color: #666666;
          line-height: 26px;
          margin-top: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .next {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 26px;
          .lt {
            font-size: 13px;
            color: #666666;
          }
          .rt {
            font-size: 13px;
            color: #666666;
            padding: 5px;
            background: #e8eaed;
            margin-left: 10px;
          }
        }
        .pos__icon {
          position: absolute;
          width: 62px;
          right: 0;
          top: 40px;
        }
      }
    }
  }
  .box4 {
    background: #ffffff;
    padding: 20px;
    display: flex;
    margin-top: 16px;
    flex-direction: column;
    .title__img {
      width: 770px;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #eeeeee;
      .tabs {
        display: flex;
        li {
          font-size: 18px;
          color: #999999;
          margin-right: 26px;
          padding: 14px 0;
          cursor: pointer;
          &.current {
            font-size: 22px;
            font-weight: 600;
            color: #333333;
            border-bottom: 4px solid #5cbd31;
          }
        }
      }
      .more {
        font-size: 14px;
        color: #999999;
        cursor: pointer;
      }
    }
    .el-row {
      margin-top: 18px;
      .el-col {
        margin-bottom: 18px;
        cursor: pointer;
        div {
          font-size: 16px;
          color: #333333;
          text-align: center;
          padding: 10px 0;
          background: #f9fafc;
          border: 1px solid #edf1f7;
          .outChainUrl {
            color: #ff0000;
          }
        }
      }
    }
  }
}
</style>