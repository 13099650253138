import request from '../utils/request';

//民间智库
export const getDeptList = (deptCategory, size, params) => request({
  url: '/api/blade-system/dept/front/list',
  method: 'get',
  showLoading:false,
  params: {
    ...params,
    deptCategory,
    size,
    targetCategory: 101
  },
});
export const getDeptNewList = (deptCategory, size, params) => request({
  url: '/api/blade-system/dept/front/show-list',
  method: 'get',
  showLoading:false,
  params: {
    ...params,
    deptCategory,
    size,
    targetCategory: 101
  },
});
//民间智库
// export const getDeptList = (deptCategory, size, params) => request({
//   url: '/api/blade-system/dept/front/list',
//   method: 'get',
//   showLoading:false,
//   params: {
//     ...params,
//     deptCategory,
//     size,
//   },
// });

