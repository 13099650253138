import request from '../utils/request';

// 获取智库列表
export const getZKPage = (current, size, params) => request({
  url: '/api/blade-zk/essay/front/page',
  method: 'get',
  params: {
    current, 
    size, 
    descs:'create_time',
    ...params
  },
});
// 获取智库详情
export const getZKDetail = (id) => request({
  url: '/api/blade-zk/essay/front/detail',
  method: 'get',
  params: {
    id
  },
});